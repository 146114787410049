<template>
  <div class="login">
    <div class="login-content" v-show="isSysadm && !selfLogin">
      <h3 class="login-title">统一身份认证系统</h3>
      <p>系统管理登录</p>
      <a-form-model :model="loginForm" :rules="loginFormRules" ref="loginForm" @keyup.enter.native="loginSubmit" style="width:340px;margin:20px auto 0;" :label-col="{span: 6}" :wrapper-col="{span: 16}">
        <a-form-model-item prop="username" label="账号">
          <a-input type="text" v-model.trim="loginForm.username" placeholder="请输入账号" ref="loginInput" />
        </a-form-model-item>
        <a-form-model-item prop="password" label="密码">
          <a-input type="password" v-model.trim="loginForm.password" placeholder="请输入密码" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{span: 22, offset: 0}">
          <a-button type="primary" :spinning="btnLoading" @click="loginSubmit" style="width:89%;margin-left: 11%;">登录</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { mapGetters } from 'vuex'
import { Auth } from '@/model'
import JSEncrypt from '@/common/jsencrypt.min'
export default {
  data () {
    return {
      isSysadm: true,
      menuRoute: {
        '01000000': 'user',
        '02000000': 'access',
        '03000000': 'group',
        '04000000': 'system',
        '05000000': 'logaudit',
        '06000000': 'news'
      },
      loginForm: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ]
      },
      btnLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'userModify',
      'userAccountModify',
      'menu',
      'selfLogin'
    ])
  },
  methods: {
    async loginSubmit () {
      this.$refs['loginForm'].validate(async (valid) => {
        if (valid) {
          this.btnLoading = true
          let res1 = await Auth.getIndexKey()
          if (!res1['code']) {
            var param = {
              'username': this.loginForm.username,
              'password': this.loginForm.password,
              'timestamp': this.getTimeFn()
            }
            var encrypted = sm2.encrypt(JSON.stringify(param), res1.data)
            // var pk = sm2.keyPair()
            // console.log(param)
            // var sesecurityStr = encrypt.encrypt(JSON.stringify(param))
            // console.log(sesecurityStr)
            // console.log(encodeURIComponent(sesecurityStr))
            let res = await Auth.loginNative({
              data: encodeURIComponent(encrypted)
            })
            if (!res['code']) {
              this.$cookie.set('user', true)
              this.$store.dispatch('user', true)
              this.$store.dispatch('selfLogin', true)
              this.getUserInfo()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          } else {
            this.$message.error(this.errMsg[res1['code']])
          }
          this.btnLoading = false
          // var encrypt = new JSEncrypt();
          // encrypt.setPublicKey(this.config.pk);
        } else {
          return false;
        }
      })
    },
    getTimeFn () {
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let fullMonth = month < 10 ? '0' + month : month
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      let hours = date.getHours()
      let fullHours = hours < 10 ? '0' + hours : hours
      let minutes = date.getMinutes()
      let fullMinutes = minutes < 10 ? '0' + minutes : minutes
      let seconds = date.getSeconds()
      let fullSeconds = seconds < 10 ? '0' + seconds : seconds
      let time = String(year) + String(fullMonth) + String(day) + String(fullHours) + String(fullMinutes) + String(fullSeconds)
      return time
    },
    async getUserInfo () {
      let res = await Auth.getUserinfo()
      if (!res['code']) {
        this.$cookie.set('user', res['data'])
        this.$store.dispatch('user', res['data'])
        let menu = {
          data: {}
        }
        if (res['data']['privilege']['user']) { // 人员管理
          for (let key in res['data']['privilege']['user']) {
            let item = res['data']['privilege']['user'][key]
            if (item.privilege === '*') {
              this.$store.dispatch('userModify', true)
              this.$store.dispatch('userAccountModify', true)
              break
            } else {
              if (item.privilege && item.privilege.length) {
                for (let k in item.privilege) {
                  if (item.privilege[k].indexOf('modify') > -1) {
                    this.$store.dispatch('userModify', true)
                  }
                  if (item.privilege[k].indexOf('account/modify') > -1) {
                    this.$store.dispatch('userAccountModify', true)
                  }
                }
              }
            }
          }
          // console.log('userModify', this.userModify)
          // console.log('userAccountModify', this.userAccountModify)
          for (let key in res['data']['privilege']['user']) {
            let item = res['data']['privilege']['user'][key]
            if (item.privilege === '*') {
              menu.data['user'] = [
                '/user/index',
                '/user/batchuser',
                '/user/batchusermodify',
                '/user/batchsearch',
                '/user/batchaccount',
                '/user/batchaccountmodify',
                '/user/batchuseraccount',
                '/user/batchuseraccountmodify'
              ]
              break
            }
            for (let k in item.privilege) {
              if (item.privilege[k].length === 1 && (item.privilege[k][0] === 'query' || item.privilege[k][0] === 'query_mg')) {
                menu.data['user'] = [
                  '/user/index',
                  '/user/batchuser',
                  '/user/batchusermodify',
                  '/user/batchsearch',
                  '/user/batchaccount',
                  '/user/batchaccountmodify',
                  '/user/batchuseraccount',
                  '/user/batchuseraccountmodify'
                ]
                break
              }
            }
          }
        }
        if (res['data']['privilege']['service']) { // 系统接入
          menu.data['access'] = [
            '/access/index'
          ]
        }
        if (res['data']['privilege']['groups']) { // 组织机构
          menu.data['group'] = []
          if (res['data']['privilege']['groups']['privilege'] === '*') {
            menu.data['group'] = [
              '/group/department',
              '/group/tag',
              '/group/usertype',
              '/group/filters'
            ]
          } else {
            for (let key in res['data']['privilege']['groups']['privilege']) {
              let item = res['data']['privilege']['groups']['privilege'][key]
              if (item[0] === 'department') {
                menu.data['group'].push('/group/department')
              } else if (item[0] === 'tag') {
                menu.data['group'].push('/group/tag')
              } else if (item[0] === 'usertype') {
                menu.data['group'].push('/group/usertype')
              } else if (item[0] === 'filter') {
                menu.data['group'].push('/group/filters')
              }
            }
          }
        }
        if (res['data']['privilege']['statistic/query']) { // 系统管理-统计分析
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['statistic/query']['privilege'] === '*') {
            menu.data['system'].push('/system/analysis/index')
            menu.data['system'].push('/system/analysis/face')
            menu.data['system'].push('/system/analysis/login')
            menu.data['system'].push('/system/analysis/active')
            menu.data['system'].push('/system/analysis/ip')
          } else {
            for (let key in res['data']['privilege']['statistic/query']['privilege']) {
              let item = res['data']['privilege']['statistic/query']['privilege'][key]
              if (item[0] === 'general') {
                menu.data['system'].push('/system/analysis/index')
              } else if (item[0] === 'facerecognition') {
                menu.data['system'].push('/system/analysis/face')
              } else if (item[0] === 'login') {
                menu.data['system'].push('/system/analysis/login')
              } else if (item[0] === 'serviceaccess') {
                menu.data['system'].push('/system/analysis/active')
              } else if (item[0] === 'ipaccess') {
                menu.data['system'].push('/system/analysis/ip')
              }
            }
          }
        }
        if (res['data']['privilege']['authorization']) { // 系统管理-权限管理
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['authorization']['privilege'] === '*') {
            menu.data['system'].push('/system/power/index')
            menu.data['system'].push('/system/power/roles')
          } else {
            for (let key in res['data']['privilege']['authorization']['privilege']) {
              let item = res['data']['privilege']['authorization']['privilege'][key]
              if (item[0] === 'user') {
                menu.data['system'].push('/system/power/index')
              } else if (item[0] === 'role') {
                menu.data['system'].push('/system/power/roles')
              }
            }
          }
        }
        if (res['data']['privilege']['app']) { // 系统管理-接口管理
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          menu.data['system'].push('/system/interface/index')
          menu.data['system'].push('/system/interface/statistics')
          menu.data['system'].push('/system/interface/api')
          menu.data['system'].push('/system/interface/add')
        }
        if (res['data']['privilege']['config']) { // 系统管理-系统配置
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['config']['privilege'] === '*') {
            menu.data['system'].push('/system/configuration/index')
            menu.data['system'].push('/system/configuration/basic')
            menu.data['system'].push('/system/configuration/syslogsetting')
            menu.data['system'].push('/system/configuration/accesspolicy')
            menu.data['system'].push('/system/configuration/property')
          } else {
            for (let key in res['data']['privilege']['config']['privilege']) {
              let item = res['data']['privilege']['config']['privilege'][key]
              if (item[0] === 'securitypolicy') {
                menu.data['system'].push('/system/configuration/index')
              } else if (item[0] === 'basicsetting') {
                menu.data['system'].push('/system/configuration/basic')
              } else if (item[0] === 'accesspolicy') {
                menu.data['system'].push('/system/configuration/accesspolicy')
              } else if (item[0] === 'syslogsetting') {
                menu.data['system'].push('/system/configuration/syslogsetting')
              } else if (item[0] === 'property') {
                menu.data['system'].push('/system/configuration/property')
              }
            }
          }
        }
        if (res['data']['privilege']['attribute']) { // 系统管理-数据定义
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['attribute']['privilege'] === '*') {
            menu.data['system'].push('/system/attribute/index')
          }
        }
        if (res['data']['privilege']['backup']) { // 系统管理-数据备份
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['backup']['privilege'] === '*') {
            menu.data['system'].push('/system/backup/index')
            menu.data['system'].push('/system/backup/external')
            menu.data['system'].push('/system/backup/importlog')
            menu.data['system'].push('/system/backup/export')
            menu.data['system'].push('/system/backup/import')
            menu.data['system'].push('/system/backup/externallog')
          } else {
            for (let key in res['data']['privilege']['backup']['privilege']) {
              let item = res['data']['privilege']['backup']['privilege'][key]
              if (item[0] === 'sysdata') {
                menu.data['system'].push('/system/backup/index')
                menu.data['system'].push('/system/backup/importlog')
                menu.data['system'].push('/system/backup/export')
                menu.data['system'].push('/system/backup/import')
              } else if (item[0] === 'external') {
                menu.data['system'].push('/system/backup/external')
                menu.data['system'].push('/system/backup/externallog')
              }
            }
          }
        }
        if (res['data']['privilege']['other']) { // 系统管理-其它设置
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['other']['privilege'] === '*') {
            menu.data['system'].push('/system/other/message')
            menu.data['system'].push('/system/other/task')
            menu.data['system'].push('/system/other/authentication')
          } else {
            for (let key in res['data']['privilege']['other']['privilege']) {
              let item = res['data']['privilege']['other']['privilege'][key]
              if (item[0] === 'notification') {
                menu.data['system'].push('/system/other/message')
              } else if (item[0] === 'task') {
                menu.data['system'].push('/system/other/task')
              } else if (item[0] === 'authentication/cas') {
                menu.data['system'].push('/system/other/authentication')
              }
            }
          }
        }
        // if (res['data']['privilege']['log']) { // 日志审计
        //   menu.data['logaudit'] = [
        //     '/logaudit/index'
        //   ]
        // }
        if (res['data']['privilege']['log']) { // 日志审计
          if (!menu.data['logaudit']) {
            menu.data['logaudit'] = []
          }
          menu.data['system'].push('/logaudit/index')
          if (res['data']['privilege']['log']['privilege'] === '*') {
            menu.data['logaudit'].push('/logaudit/audit')
            menu.data['logaudit'].push('/logaudit/login')
            menu.data['logaudit'].push('/logaudit/face')
          } else {
            for (let key in res['data']['privilege']['log']['privilege']) {
              let item = res['data']['privilege']['log']['privilege'][key]
              if (item[0] === 'audit/query') {
                menu.data['logaudit'].push('/logaudit/audit')
              } else if (item[0] === 'login/query') {
                menu.data['logaudit'].push('/logaudit/login')
              } else if (item[0] === 'facerecognition/query') {
                menu.data['logaudit'].push('/logaudit/face')
              }
            }
          }
        }
        if (res['data']['privilege']['publish']) { // 资讯发布
          menu.data['news'] = [
            '/news/index'
          ]
        }
        if (res['data']['decentralization']) { // 二级授权
          menu.data['decentralization'] = [
            '/decentralization/index'
          ]
        }
        menu['data']['permission'] = ['/permission']
        menu['data']['permission2'] = ['/permission2']
        menu['data']['login'] = ['/login']
        this.getMenu(menu)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getMenu (res) {
      this.$store.dispatch('menu', res['data'])
      for (let k in this.menu) {
        this.$router.push(this.menu[k][0])
        break
      }
      this.vueRouterHandle()
    },
    vueRouterHandle () {
      this.$router.beforeEach((to, from, next) => {
        if (to.path === '/group/department') {
          if (this.menu['group']) {
            if (this.menu['group'].indexOf(to.path) > -1) {
              next()
            } else {
              this.$router.push(this.menu['group'][0])
              next()
            }
          } else {
            this.$message.error('您没有权限访问该页面！')
          }
        } else if (to.path === '/system/analysis/index') {
          if (this.menu['system']) {
            if (this.menu['system'].indexOf(to.path) > -1) {
              next()
            } else {
              this.$router.push(this.menu['system'][0])
              next()
            }
          } else {
            this.$message.error('您没有权限访问该页面！')
          }
        } else if (to.path === '/logaudit/login') {
          if (this.menu['logaudit']) {
            if (this.menu['logaudit'].indexOf(to.path) > -1) {
              next()
            } else {
              this.$router.push(this.menu['logaudit'][0])
              next()
            }
          } else {
            this.$message.error('您没有权限访问该页面！')
          }
        } else {
          let routeStatus = false
          for (let key in this.menu) {
            if (this.menu[key].indexOf(to.path) > -1) {
              routeStatus = true
            }
          }
          if (!routeStatus) {
            this.$message.error('您没有权限访问该页面！')
          } else {
            next()
          }
        }
      })
    }
    // async getMenu () {
    //   let res = await Auth.getMenu()
    //   if (!res['code']) {
    //     // this.menu = res['data']
    //     this.$store.dispatch('menu', res['data'])
    //     // this.menu = [{'id': '02000000'}]
    //     let route = this.$route.path.split('/')[1]
    //     // console.log(route)
    //     let routeCode = null
    //     for (let key in this.menuRoute) {
    //       if (this.menuRoute[key] === route) {
    //         routeCode = key
    //         break
    //       }
    //     }
    //     let menuItem = res['data'].find(item => {
    //       return item.id === routeCode
    //     })
    //     if (!menuItem) {
    //       this.$router.push('/' + this.menuRoute[res['data'][0]['id']])
    //     }
    //   } else {
    //     this.$message.error(this.errMsg[res['code']])
    //   }
    // }
  }
};
</script>

<style scoped>
.login {
  margin: -10px;
  background: #eee;
  width: 100vw;
  height: 100vh;
}
</style>
